.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: 1.2rem;
  color: white;
  font-family: "nanits-font";
  padding-right: 10px;
  padding-left: 10px;
}

.header {
  margin-top: 50px;
  font-size: 2rem;
  max-width: 658px;
  p {
    font-size: 1.5rem;
    text-align: left;
  }
}

.message {
  margin-top: 20px;
  max-width: 600px;
  text-align: left;
  margin-bottom: 50px;
  p {
    line-height: 2;
  }
}

.App-logo {
  margin-top: 100px;
  height: 15vmin;
  pointer-events: none;
  max-width: 650px;
}

.App-logo2 {
  height: 10vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-link {
  color: #61dafb;
  text-decoration: none;
}

.links {
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-evenly;
}

.supporters-logos {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: flex-start;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.supporters-texts {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.supporter {
  padding-right: 30px;
}

.supporter-text {
  padding-right: 10px;
  padding-bottom: 10px;
}

.footer {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "nanits-font";
  background-color: white;
  height: 50px;
  font-size: 1rem;
  padding-left: 50px;
  padding-right: 50px;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
